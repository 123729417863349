import React from 'react'
import { graphql } from 'gatsby'

import { SinglePage } from '../../../templates/SinglePage'
import { SocialSharing } from '../../../components/shared'
import { Seo } from '../../../components/base'
import { getPageBuilderComponents } from '../../../components/base/PageBuilderComponents'
import { getLocaleMarket } from '../../../utils/getLocaleMarket'

const PostPage = ({ data, location, pageContext }) => {
  const {
    pageBuilder,
    image,
    title,
    category,
    desc,
    subtitle,
    icon,
    titleIcon,
    fullWidthHeader = false
  } = data.sanityPost
  const languages = { pageContext }
  let myMarket = getLocaleMarket()
  return (
    <SinglePage
      icon={icon}
      titleIcon={titleIcon}
      heroImage={image}
      noHeroTitle
      translations={languages}
      fullWidthHeader={fullWidthHeader}>
      <Seo
        title={`${category}: ${title}`}
        description={desc}
        image={image?.image?.asset?.url}
      />
      <section className="md:mx-auto singlePageSection">
        <div className="mb-20 mt-10">
          <h1 className="text-center" data-cy="post-title">
            {title}
          </h1>
          {subtitle && (
            <p
              className="md:mt-3 text-lg md:text-xl text-center uppercase"
              style={{ lineHeight: '1.1666' }}>
              {subtitle}
            </p>
          )}
          {pageBuilder.map((item, index) => (
            <React.Fragment key={index}>
              {getPageBuilderComponents(item, myMarket, pageContext)}
            </React.Fragment>
          ))}
        </div>
        <SocialSharing link={location} />
      </section>
    </SinglePage>
  )
}

export default PostPage

export const query = graphql`
  query ($id: String) {
    sanityPost(_id: { eq: $id }) {
      title
      desc
      fullWidthHeader
      subtitle
      category
      icon {
        ...ImageObjectQuery
      }
      titleIcon {
        ...ImageObjectQuery
      }
      image {
        ...ImageObjectQuery
      }
      ...PageBuilderElements
    }
  }
`
